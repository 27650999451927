@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
}
.home {
  /* background: linear-gradient(pink, #f5f5f5, rgb(238, 171, 204)); */
  background-color: #fbe9e7;
}

.slide-image img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
.home-mid-content {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: space-around;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding: 35px;
}

.mid-content-left img {
  object-fit: contain;
  border-radius: 10px;
  width: 650px;
  height: auto;
}

.mid-content-right {
  font: normal normal 27px/40px "Roboto Slab";
  text-align: center;
  color: black;
}
.mid-content-right p {
  margin-bottom: 5rem;
}
.home-profile-content {
  text-align: left;
  padding: 70px;
  margin-top: 3rem;
  color: black;
  width: 100%;
  border-bottom: 1px solid black;
  border-bottom-color: 2px solid black;
}
.home-profile-content h3 {
  font: normal normal bold 34px/1 "Roboto Slab";
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 45px;
  text-align: center;
  color: black;
}
.home-profile-content p {
  font: normal normal normal 24px/44px "Roboto Slab";
  letter-spacing: 1px;
}
.home-profile-content ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4rem;
  font: normal normal bold 20px "Roboto Slab";
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 5rem;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .home-profile h3 {
    font: normal normal bold 25px/40px "Roboto Slab";
    margin-bottom: 15px;
  }
  .home-mid-content {
    flex-wrap: wrap;
  }
  .home-profile p {
    font: normal normal normal 16px/30px "Roboto Slab";
    text-align: justify;
    letter-spacing: 0.5px;
  }
  .home-profile-content ul {
    display: flex;
    justify-content: flex-start;
    font: normal normal bold 18px/30px "Roboto Slab";
    gap: 2rem;
    margin-top: 2rem;
  }
  .mid-content-left img {
    width: 100%;
  }
  .mid-content-right p {
    font: normal normal 20px/40px "Roboto Slab";
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .home-profile-content {
    padding: 15px;
  }
}
