@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");

/* .about {
  background-color: #fff8e1;
  padding-top: 3rem;
  padding-bottom: 2rem;
} */
.about-head {
  text-align: center;
  margin-top: 4rem;
}
.about-head h3 {
  font: normal bold 30px/1 "Roboto Slab";
  margin-bottom: 25px;
  letter-spacing: 0.5px;
}
.about-head p {
  font: normal normal 21px/40px "Roboto Slab";
  letter-spacing: 0.5px;
}
.home-service-card {
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #e7e7e7;
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 30px;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
}
.card-one,
.card-two,
.card-three,
.card-four {
  width: 300px;
  height: max-content;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
}

.app-logo {
  font-size: 120px;
  color: black;
  border-radius: 40px;
  background-color: white;
  padding: 17px;
}
.service-card-title {
  font: normal normal bold 24px "Roboto Slab";
  margin-top: 40px;
}
.service-card-content {
  font: normal normal 20px/40px "Roboto Slab";
  margin-top: 20px;
}
.quality-team {
  width: 100%;
  height: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.quality-team img {
  width: 500px;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}
.quality-team {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
}
.quality-team h3 {
  font: normal normal bold 30px/1 "Roboto Slab";
  letter-spacing: 0.5px;
}
.quality-team p {
  font: normal normal normal 22px/40px "Roboto Slab";
  margin-top: 1rem;
  padding: 5px;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .card-one,
  .card-two,
  .card-three,
  .card-four {
    margin-bottom: 1rem;
    padding: 10px;
    width: 100%;
  }
  .quality-team {
    flex-wrap: wrap;
  }
  .quality-team img {
    width: 100%;
  }
  .about h3 {
    font: normal normal bold 25px/35px "Roboto Slab";
  }
  .quality-team {
    /* margin: 10px; */
  }
  .quality-team p {
    font: normal normal normal 18px/30px "Roboto Slab";
  }
  .home-service-card {
    margin-top: 3rem;
  }
  .about-head p {
    font: normal normal 18px/35px "Roboto Slab";
  }
  .service-card-content {
    font: normal normal 18px/35px "Roboto Slab";
  }
  .about-head {
    margin-top: 2rem;
  }
}
