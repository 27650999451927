@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");
.process {
  background-color: #ffebee;
  padding-top: 20px;
}
.process-head {
  text-align: center;
  margin-top: 45px;
  letter-spacing: 0.5px;
}
.process-head h3 {
  font: normal bold 30px/1 "Roboto Slab";
  margin-bottom: 20px;
}
.process-head p {
  font: normal normal 21px/40px "Roboto Slab";
}
.process-method {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}
.process-method img {
  width: 200px;
  height: 200px;
  border-radius: 15px;
}
.process-card {
  padding: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
  /* background: linear-gradient(pink, #f5f5f5, rgb(238, 171, 204)); */
  background-color: #8d6e63;
  color: black;
  border-radius: 15px;
}
.process-method h3 {
  font: normal normal bold 30px/1 "Roboto Slab";
  letter-spacing: 1px;
  text-transform: capitalize;
}
.process-method p {
  font: normal normal 20px/40px "Roboto Slab";
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .process-head h3,
  .process-method h3 {
    font: normal normal bold 25px/40px "Roboto Slab";
    text-align: center;
  }
  .process-head p,
  .process-method p {
    font: normal normal normal 16px/30px "Roboto Slab";
    text-align: justify;
  }
  .process-card {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
}
