@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");

.contact-map iframe {
  width: 500px;
  height: 400px;
}
.contact {
  display: flex;
  justify-content: center;
  gap: 8rem;
  margin-bottom: 4rem;
  align-items: center;
  flex-wrap: wrap;
  height: 60vh;
}
.contact-left-flex h3 {
  font: normal normal bold 30px "Roboto Slab";
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.contact-right-flex h3 {
  font: normal normal bold 30px "Roboto Slab";
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}
.contact-right-flex h4 {
  font: normal normal bold 25px/1 "Roboto Slab";
  margin-bottom: 20px;
}
.contact-right-flex p {
  font: normal normal 20px "Roboto Slab";
}
.contact-section {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 760px) {
  .contact-map iframe {
    width: 100%;
  }
  .contact {
    text-align: center;
    gap: 4rem;
    height: auto;
  }
  .contact h3 {
    font: normal normal bold 27px/1 "Roboto Slab";
  }
  .contact h4 {
    font: normal normal bold 20px/1 "Roboto Slab";
  }
  .contact p {
    font: normal normal 16px/25px "Roboto Slab";
  }
}
